<template>
	<main class="page-container container">
		<div class="section__top" :class="isMobileSmall ? 'mb-10' : 'mb-20'">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 30"
					class="font_Hsemibold"
				>
					{{ $t('directContracts') }}
				</AppText>
			</div>
		</div>

		<div class="section__body plan-graph" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
			<div class="table-block" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
				<table>
					<thead>
					<tr>
						<th>№</th>
						<th>{{ $t('contractNo') }}</th>
						<th style="max-width: 250px">{{ $t('contractDate') }} ({{ $t('workAndServices') }})</th>
						<th style="max-width: 300px">{{ $t('customerName') }}</th>
						<th>{{ $t('contractAmount') }}</th>
						<th>{{ $t('PerformerName') }}</th>
					</tr>
					</thead>
					<tbody>
					<template v-if="list.length > 0">
						<tr v-for="item in list" :key="item.id">
							<td>{{ item.id }}</td>
							<td style="max-width: 300px">
								<router-link :to="`/direct-purchases/details/${item.id}`"
											 class="link-blue"
								>
									{{ item.numberContract }}
								</router-link>
							</td>
							<td style="max-width: 250px">{{ item.dogovrDate }}</td>
							<td>{{ item.companyCustomerName | translate }}</td>
							<td>
								<AppText weight="600" size="14" line-height="16">{{ item.price | price }}</AppText>
							</td>
							<td>{{ item.nameCompany }}</td>
						</tr>
					</template>
					<tr v-else>
						<td colspan="6">
							<span>{{ $t('noData') }}</span>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			<pagination
				:total="this.total"
				:perPage="this.query.take"
				@paginate="pagination($event)"
			>
			</pagination>
		</div>
	</main>
</template>
<script>
	import AppText from "@/components/shared-components/AppText";
	import AppButtonA from "@/components/shared-components/AppButtonA";
	import FormLabel from "@/components/shared-components/FormLabel";
	import AppButton from "@/components/shared-components/AppButton";
	import AppPagination from "@/components/shared-components/AppPagination";
	import Pagination from "@/components/shared-components/Pagination";
	import AppModal from "@/components/shared-components/AppModal";
	import ClientService from '@/services/api/client-service';
	import AppContentCard from '@/components/shared-components/AppContentCard';
	import BlockWrap from '@/components/shared-components/BlockWrap';
	import AppMultipleSelect from '@/components/shared-components/AppMultipleSelect';

	export default {
		name: 'index',
		data() {
			return {
				query: {
					numberAttorney: null,
					nameProduct: null,
					typeDogovor: null,
					skip: 0,
					take: 10
				},
				list: [],
				total: 0
			}
		},
		components: {
			AppModal,
			AppPagination,
			AppButton,
			FormLabel,
			AppButtonA,
			AppText,
			Pagination,
			AppContentCard,
			BlockWrap,
			AppMultipleSelect
		},
		created() {
			this.load();
		},
		methods: {
			load() {
				ClientService.allDirectPurchases(this.query)
					.then(response => {
						this.list = response.data.result.data;
						this.total = response.data.result.total;
					})
			}
		}
	}
</script>
<style lang='scss' scoped>
	a {
		text-decoration: underline;
		color: cornflowerblue;
	}
	.table-block {
		table{
			tr{
				th{
					text-align: left;
					font-family: 'MazzardHMedium';
				}
				td{
					text-align: left;
				}
			}
		}
	}
</style>
